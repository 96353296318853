
export default function Header () {
  return ( 
    <div className="phsoftware-header">
          <div className="container-fluid">
               <div className="row">
                    <div className="col-sm-1 col-lg-1">
                         <img src="/images/PHSoftware_LogoOnly.png" style={{"width":"25px", "top":"0"}} alt=""/>
                    </div>
                    <div className="col-sm-3 col-lg-3">
                         <h3 className="lead phsoftware-title">Omnis List search</h3>
                    </div>
                    <div className="col-sm-8 col-lg-8">
                         <p className="lead phsoftware-description ">A search for emails sent to the Omnis list server since May 2008.</p>
                    </div>
               </div>
          </div>
     </div>
     )
}

