import {  Outlet } from "react-router-dom"; //Navigate,

import Header from "./components/Header";
import Footer from "./components/Footer";

export default function Layout() {
  return ( 
    <div className="container-fluid" style={{"padding": "0px"}}>
      <Header />

      <div className="container-fluid">
        <div className="row col-sm-12">
          <div className="col-sm-12 phsoftware-main">
            <Outlet />
          </div>
        </div>
      </div>

      <Footer />
    </div>
   );
}
