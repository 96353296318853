import { useEffect, useState } from "react";
import Search from "./Search";
import axiosClient from "../axios-client";


export default function OmnisList () {

  interface emailEntryInterface {
    id:           number,
    emessageid:   string,
    efrom:        string,
    esubject:     string,
    edate:        string
  }
  type emailListType = emailEntryInterface[];

  interface emailInterface {
    id:           number,
    emessageid:   string,
    efrom:        string,
    esubject:     string,
    edate:        string,
    etext:        string,
    ereference:   string,
    einreplyto:   string
  }

  const [emailList, setEmailList] = useState<emailEntryInterface[]>([]);

  const [email, setEmail] = useState<emailInterface>();
  const [myid, setMyid] = useState(0);

  const [listLoaded, setListLoaded] = useState(false);

  useEffect (() => {
    if (myid>0) {
      // console.log(myid);
      axiosClient.get(`/show/${myid}`)
      .then(({data})=> {
        let result = data.data[0];
        result.etext = decodeURIComponent(result.etext);
        result.etext = "<p>" + result.etext.replace(/<br>/g, "</p><p>") + "</p>";

        result.efrom = result.efrom.replace(/&lt;/g, "<");
        result.efrom = result.efrom.replace(/&gt;/g, ">");
        result.efrom = result.efrom.replace(/&quot;/g, "\"");
        setEmail(result);
      })
      .catch(err => {
          const response = err.response;
          if (response && ((response.status === 422)||(response.status === 418))) { // Validation error!
              if (response.data.errors){
                  console.error('Error executing reCAPTCHA:', err);
                  // setErrors(response.data.errors)
              } else {
                  console.error('Error executing reCAPTCHA:', err);
                  // setErrors(response.data.message)
              }
          }
      })
    }
  },[myid])

  function setFoundList (mydata: emailListType)
  {
    setEmailList(mydata);
    setListLoaded(true);
  }

  return ( 
    <div className="col-md-12">
        
      <div className="row">
        <div className="col-md-12">
          <Search setFoundList = {setFoundList} />
        </div>
      </div>
      <hr/>
      <div className="row col-md-12">
        <div className="col-md-5 phs_resize">
          <div className="phsoftware-emaillist">
          {listLoaded &&
            <ul id="myUL">
               {emailList?.map(e => (
                <li onClick={ () => setMyid(e.id) } key={ e.id }>
                  {(myid === e.id) &&
                    <span className='highlight'>
                      {e.edate} - {decodeURI(e.esubject).replace(/&#039;/g, "'")} - {decodeURI(e.efrom)}
                    </span>
                  }
                  {(myid !== e.id) &&
                    <span className='normal'>
                      {e.edate} - {decodeURI(e.esubject).replace(/&#039;/g, "'")} - {decodeURI(e.efrom)}
                    </span>
                  }
                </li>
                ))}
            </ul>
          }
          </div>
        </div>

        <div className="col-md-7 phs_therest" id="contents">
          <div className="phsoftware-email">
            {email &&
            <div>
              <p>{email?.edate}
              <br/>{decodeURI(email?.esubject).replace(/&#039;/g, "'") }
              <br/>{decodeURI(email?.efrom) }</p>
              <hr/>
              {<div dangerouslySetInnerHTML={{ __html: email?.etext}} />}
            </div>
            }
          </div>
        </div>
        
      </div>
    </div>
   );

}
