
export default function Footer () {
  return ( 
    <div className="phsoftware-footer text-muted">
      <div className="container-fluid">
        <div className="row col-sm-12 phsoftware-footer">
          <div className="col-sm-2"/>
          <div className="col-sm-8">
            &nbsp;&nbsp;&nbsp;<img src="/images/PHSoftware_LogoOnly.png" style={{"width":"15px"}} alt=""/>
            <small>&nbsp;&nbsp;Website hosted by &copy; PHSoftware.</small>
          </div>
          <div className="col-sm-2 smaller-font">
            <p>using Google reCaptcha</p>
          </div>
        </div>
      </div>
    </div>
   );
}